.collapse > .ant-collapse-header {
  display: none;
}
.ant-collapse {
  border-color: transparent;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-width: 0px;
}

.ant-collapse-content {
  padding: 0px;
  border-bottom-color: transparent;
  border-top-color: transparent;
  border-width: 0px;
}
.ant-collapse-content-box {
  padding: 0px;
}

.ant-collapse > .ant-collapse-item {
  border-width: 0px;
}

.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-color: transparent;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0px;
  padding-left: 10px;
}
.containerHome {
  background: white;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.header {
  background: white;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  flex-wrap: wrap;
}
.container {
  background: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}
.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  min-width: 375px;
}
.swrapper {
  max-width: 1336px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  min-width: 375px;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
th.right,
td.right {
  text-align: right !important;
}

.col-1,
.col-2 {
  width: 48%;
  display: inline;
  color: black;
}

.col-1 {
  float: left;
}

.col-2 {
  float: right;
}

.col-head {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}

.ta-right {
  text-align: right !important;
}

.ta-left {
  text-align: left;
}

.ta-center {
  text-align: center;
}

.divider {
  clear: both;
  width: 100%;
  margin: 3mm;
  border-bottom: 1px solid #e0e0e0;
}

.no-flex {
  display: inline-block !important;
}

.divider-no-border {
  clear: both;
  margin: 3px;
  width: 100%;
  height: 1px;
}

.item-half-width {
  width: 45%;
  display: inline-block;
}

.width-100 {
  width: 100%;
}

.page-breaker {
  display: table;
  page-break-inside: avoid;
  margin-top: 28px;
}

.always-break {
  page-break-after: always;
}

.col-3 {
  width: 33%;
  display: inline-block;
}

.box {
  border: 1px solid #e0e0e0;
  /*margin: 8px;*/
  padding: 8px;
}

.big {
  font-size: 38px;
  font-weight: bold;
}

@page {
  size: A4;
  margin: 0;
}

@media print {
  body {
    margin: 0;
  }

  h2,
  .col-head {
    margin: 0 auto;
    font-size: 5mm;
  }
  /*  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
  }

  .container {
    padding-top: 50px !important; 
  }*/
}
